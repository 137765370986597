"use client"
import type { ImageLoader } from "next/image"
import { type BunnyFetcherArgs, getBunnyCdnUrl } from "./getBunnyCdnUrl"

/**
 * This adds a loader to the image component
 * Should not be used because it's not as optimal as Next/Image
 * @param src - image source
 * @param width - image width
 * @param quality - image quality
 * @return the image object
 */
export const BunnyLoader: ImageLoader = ({
  src,
  width,
  quality,
}: BunnyFetcherArgs) => {
  return getBunnyCdnUrl({ src, width, quality })
}

export default BunnyLoader
