import { ReactNode, Dispatch, SetStateAction } from "react"
import styles from "./styles.module.css"
import { classNames } from "@local/utils/src/classNames"
import { isBrowser } from "@local/utils"

const XMarkIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    aria-hidden="true"
    className={className}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
)

interface Props {
  title?: string
  children?: ReactNode
  open?: boolean
  toggle: Dispatch<SetStateAction<boolean>> | ((e: boolean) => void)
  shorter?: boolean
  forceSquare?: boolean
  onCloseCallback?: () => void
  disableCloseOnOnverlay?: boolean
  hideCloseButton?: boolean
}

const Modal = ({
  title,
  open,
  toggle,
  children,
  shorter,
  forceSquare,
  onCloseCallback,
  disableCloseOnOnverlay = false,
  hideCloseButton = false,
}: Props) => {
  if (!open) {
    return null
  }

  return (
    <div className={classNames(styles.Modal, "w-[100vw], h-[100vh]")}>
      <div
        className={classNames(
          // forceSquare ? "flex" : "",
          // forceSquare ? styles.Modal__Wrapper__Square : styles.Modal__Wrapper
          styles.Modal__Wrapper
        )}
      >
        <button
          className={classNames(styles.Modal__Overlay)}
          onClick={() => {
            toggle(false)
            onCloseCallback && onCloseCallback()
          }}
          disabled={disableCloseOnOnverlay}
        />

        <span className={styles.Modal__Trick} aria-hidden="true">
          &#8203;
        </span>

        <div
          className={classNames(
            shorter ? "max-w-xl" : "sm:max-w-4xl",
            styles.Modal__Inner
            // forceSquare ? styles.Modal__Inner__CarouselOnly : ""
          )}
        >
          <div className="px-4 pt-8 pb-4 mb-4 bg-white rounded-lg sm:p-6 ">
            <div className={styles.Modal__Inner__Wrapper}>
              {!hideCloseButton && (
                <button
                  type="button"
                  className={styles.Modal__Close}
                  onClick={() => {
                    toggle(false)
                    onCloseCallback && onCloseCallback()
                  }}
                >
                  <span>Close</span>
                  <XMarkIcon
                    className={styles.Modal__Close__Icon}
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
            <div>
              {title && <h3 className={styles.Modal__Title}>{title}</h3>}
              {children && (
                <div className={styles.Modal__Description}>{children}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Modal }
