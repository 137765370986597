"use client"
import { useI10n } from "@local/i10n/src/useI10n"
import useIsMobile from "../useIsMobile"
import { type Payload } from "@local/payload-client/src/types"
import { useEffect, useRef } from "react"

interface Props {
  buid?: string | null
  tall?: boolean
  price?: number | null
  theme?: "dark" | "light"
}

export const TrustpilotMicroCombo = ({
  buid: buidToUse,
  tall,
  price,
  theme = "light",
}: Props) => {
  const ref = useRef(null)
  const { currencyCode } = useI10n()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true)
      }
    }, 1000)
    return () => {
      timeout
    }
  }, [])

  return (
    <div
      ref={ref}
      className="mt-6 pointer-events-none mb-7 trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id={buidToUse}
      data-style-height={useIsMobile() || tall ? "42px" : "24px"}
      data-style-width="100%"
      data-theme={theme}
      onClick={(e) => e.preventDefault()}
      // data-price={price}
      // data-price-currency={currencyCode}
      // data-availability={true}
    />
  )
}

export const TrustpilotSlider = ({
  price,
  buid,
}: {
  price: number
  buid?: string | null
}) => {
  const ref = useRef(null)
  const { currencyCode } = useI10n()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true)
      }
    }, 1000)
    return () => {
      timeout
    }
  }, [])

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id={buid}
      data-style-height="240px"
      data-style-width="100%"
      data-theme="light"
      data-tags="SelectedReview"
      data-review-languages="en"
      // data-price-currency={currencyCode}
      // data-availability={true}
      // data-price={price}
    >
      {/* <center className="my-10">
        <a
          href="https://www.trustpilot.com/review/baerskinhoodie.com"
          target="_blank"
          rel="noreferrer"
          className="text-indigo-600 underline"
        >
          Click here to see reviews directly at Trustpilot &rarr;
        </a>
      </center> */}
    </div>
  )
}

export const TrustpilotWrapper = ({
  block,
  product,
  buid,
}: {
  block: Payload.TrustpilotWidgets
  product: Payload.Product
  buid?: string | null
}) => {
  const ref = useRef(null)
  const { currencyCode } = useI10n()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current, true)
      }
    }, 1000)
    return () => {
      timeout
    }
  }, [])

  const price =
    (currencyCode &&
      currencyCode !== "USD" &&
      product.prices?.[0]?.[currencyCode]) ??
    product.prices[0].price

  return (
    <>
      <section className="px-4 py-8">
        <header className="flex flex-col justify-center mb-2 space-y-3 uppercase md:flex-row md:space-y-0 md:space-x-3 ">
          <h3 className="text-3xl font-extrabold text-center uppercase sm:text-4xl">
            {typeof block.headline === "object"
              ? block.headline["en"]
                ? block.headline["en"]
                : block.headline
              : "Raving fans from all over the world"}
          </h3>
        </header>

        <TrustpilotSlider price={price as number} buid={buid} />
      </section>
    </>
  )
}

export default TrustpilotWrapper
