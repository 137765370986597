"use client"

import { ErrorBoundary } from "./ErrorBoundary"
import { type ReactNode, Suspense, useEffect, useState } from "react"

export const HydrationContainer = ({
  children,
  fallback,
  loadingSkeleton,
}: {
  children: ReactNode
  fallback?: ReactNode
  loadingSkeleton?: ReactNode
}) => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)

    return () => {
      setIsClient(false)
    }
  }, [])

  return isClient ? (
    <ErrorBoundary>
      <Suspense fallback={<>{loadingSkeleton}</>}>{children}</Suspense>
    </ErrorBoundary>
  ) : fallback ? (
    <>{fallback}</>
  ) : null
}

export default HydrationContainer
